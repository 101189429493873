import apiCall from "./apiCall";

export default {
  async addVendor(
    bussiness_name,
    phone,
    email,
    location,
    owners_name,
    type,
    bank_id,
    account_no,
    account_name,
    opening_branch,
    created_by,
    vendor_type = "VENDOR",
    username = ""
  ) {
    let data = {
      bussiness_name: bussiness_name,
      vendor_type: vendor_type,
      phone: phone,
      email: email,
      location: location,
      owners_name: owners_name,
      username: username,
      type: type,
      bank_id: bank_id,
      account_no: account_no,
      account_name: account_name,
      opening_branch: opening_branch,
      created_by: created_by,
    };
    let response = await apiCall("vendor/register", data);

    return response;
  },
  async listVendors(vendor_type = "VENDOR") {
    let data = { vendor_type: vendor_type };

    let response = await apiCall("vendors/list", data);
    return response;
  },
  async getVendor(vendor_id) {
    let data = { vendor_id: vendor_id };

    let response = await apiCall("vendor/get", data);
    return response;
  },
  async addVendorItem(vendor_id, menu) {
    let data = { vendor_id: vendor_id, menu_item: menu };

    let response = await apiCall("vendor/menu/add", data);
    return response;
  },
  async listVendorUsers(vendor_id) {
    let data = { vendor_id: vendor_id };

    let response = await apiCall("vendor/users/actual/list", data);
    return response;
  },
  async listVendorCodes(vendor_id) {
    let data = { vendor_id: vendor_id };

    let response = await apiCall("vendor/codes/list", data);
    return response;
  },
  async countVendors() {
    let data = {};

    let response = await apiCall("vendors/count", data);
    return response;
  },
  async listBanks() {
    let data = {};

    let response = await apiCall("banks/list", data);
    return response;
  },
};
