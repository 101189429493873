import apiCall from "./apiCall";

export default {
  async registerSalesPoint(vendor_id, sales_point) {
    let data = {
      vendor_id: vendor_id,
      sales_point: sales_point,
    };
    let response = await apiCall("vendor/salespoint/register", data);
    return response;
  },
  async updateSalesPoint(sales_point_id, sales_point) {
    let data = {
      sales_point_id: sales_point_id,
      sales_point: sales_point,
    };
    let response = await apiCall("vendor/salespoint/update", data);
    return response;
  },
  async getSalesPoint(sales_point_id) {
    let data = {
      sales_point_id: sales_point_id,
    };
    let response = await apiCall("vendor/salespoint/get", data);
    return response;
  },
  async listSalesPoints(vendor_id) {
    let data = {
      vendor_id: vendor_id,
    };

    let response = await apiCall("vendor/salespoints/list", data);
    return response;
  },
  async countSalesPoints(vendor_id) {
    let data = {
      vendor_id: vendor_id,
    };

    let response = await apiCall("vendor/salespoints/count", data);
    return response;
  },
};
