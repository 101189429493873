import apiCall from "./apiCall";

export default {
  async registerBanja(
    vendor_id,
    customer_id,
    vendor_user_id,
    credit,
    memo,
    added_by,
    date_added
  ) {
    let data = {
      vendor_id: vendor_id,
      customer_id: customer_id,
      vendor_user_id: vendor_user_id,
      credit: credit,
      memo: memo,
      added_by: added_by,
      date_added: date_added,
    };

    let response = await apiCall("vendor/banja/register", data);
    return response;
  },
  async listBanjas(vendor_id) {
    let data = { vendor_id: vendor_id };

    let response = await apiCall("vendor/banjas/list", data);
    return response;
  },
  async sumBanjas(vendor_id) {
    let data = { vendor_id: vendor_id };

    let response = await apiCall("vendor/banjas/total", data);
    return response;
  },
};
