import React, { useEffect, useState } from "react";
import AppContainer from "../Components/Structure/AppContainer";
import Loader from "../Components/Common/Loader";
import Alert from "../Components/Common/Alert";
import AddVendor from "../Components/Modal/AddVendor";
import { Link } from "react-router-dom";
import ajaxVendor from "../util/remote/ajaxVendor";

function Vendor() {
  const [vendorsList, setVendorsList] = useState("");
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState({ page: 1 });

  const listVendors = async () => {
    setLoading(true);
    const server_response = await ajaxVendor.listVendors();
    setLoading(false);

    if (server_response.status === "OK") {
      setVendorsList(server_response.details);
    } else {
      setVendorsList("404");
    }
  };

  useEffect(() => {
    listVendors();
  }, [page]);

  return (
    <AppContainer title={"Vendors"} href={"home"} parent={"Home"}>
      <div className="col-12">
        <div className="card table-card">
          <div className="card-header">
            <h4 style={{ float: "left" }}>Vendors</h4>
            <button
              type="button"
              className="btn  btn-primary mr-2 mb-2"
              data-bs-toggle="modal"
              data-bs-target=".add-vendor"
              style={{ float: "right" }}
            >
              Add Vendor
            </button>
            <AddVendor />
          </div>
          <div className="card-body">
            {loading && <Loader />}
            {!loading && (
              <div className="table-responsive">
                <table className="table" id="pc-dt-simple">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Vendor</th>
                      <th>Vendor No</th>
                      <th>Users</th>
                      <th>Email</th>
                      <th>Location</th>
                      <th>Owner</th>
                      <th>Transfer Type</th>
                      <th>Bank</th>
                      <th>Account Names</th>
                      <th>Opening Branch</th>
                      <th>Created At</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!vendorsList && <Loader />}
                    {vendorsList && vendorsList === "404" && (
                      <Alert type="danger" message="No vendors found" />
                    )}
                    {vendorsList &&
                      Array.isArray(vendorsList) &&
                      vendorsList.map((vendor, key) => (
                        <tr key={key}>
                          <td>{key + 1}</td>
                          <td>
                            {vendor.business_name}
                            <br />
                            {vendor.phone}
                          </td>
                          <td>{vendor.vendor_no}</td>
                          <td>
                            <Link to={`/vendor_users/${vendor.id}`}>
                              <span className="badge bg-primary">
                                vendor users
                              </span>
                            </Link>
                          </td>
                          <td>{vendor.email}</td>
                          <td>{vendor.location}</td>
                          <td>{vendor.owners_name}</td>
                          <td>
                            {vendor.transfer_type === "1"
                              ? "Instant"
                              : vendor.transfer_type === "2"
                              ? "Batched"
                              : vendor.transfer_type === "3"
                              ? "No Transfer"
                              : ""}
                          </td>
                          <td>
                            {vendor.bank.bank_name}
                            <br />
                            {vendor.account_no}
                          </td>
                          <td>{vendor.account_name}</td>
                          <td>{vendor.opening_branch}</td>
                          <td>{vendor.created_at.long_date}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
    </AppContainer>
  );
}

export default Vendor;
