import React, { useContext, useState } from "react";
import Loader from "../Common/Loader";
import Alert from "../Common/Alert";
import ajaxVendor from "../../util/remote/ajaxVendor";
import VendorContext from "../../Context/VendorContext";
import functions from "../../util/functions";

function AddVendor() {
  const [business_name, setBusiness] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [location, setLocation] = useState("");
  const [owners_name, setOwner] = useState("");
  const [type, setType] = useState("");
  const [bank_id, setBank] = useState("");
  const [account_name, setAccountname] = useState("");
  const [account_no, setAccountno] = useState("");
  const [opening_branch, setOpeningbranch] = useState("");
  const [created_by, setCreatedby] = useState(functions.sessionGuard());

  const [loading, setLoading] = useState("");
  const [info, setInfo] = useState("");

  const { listVendors, banksList } = useContext(VendorContext);

  const registerVendor = async (e) => {
    if (
      business_name.length > 0 &&
      phone.length > 0 &&
      email.length > 0 &&
      location.length > 0 &&
      type.length > 0 &&
      bank_id.length > 0 &&
      account_no.length > 0 &&
      account_name.length > 0 &&
      created_by.length > 0
    ) {
      setLoading(true);
      const server_response = await ajaxVendor.addVendor(
        business_name,
        phone,
        email,
        location,
        owners_name,
        type,
        bank_id,
        account_no,
        account_name,
        opening_branch,
        created_by
      );
      setLoading(false);

      if (server_response.status === "OK") {
        listVendors();
        setBusiness("");
        setPhone("");
        setEmail("");
        setLocation("");
        setOwner("");
        setType("");
        setBank("");
        setAccountname("");
        setAccountno("");
        setOpeningbranch("");
        setInfo(<Alert type="success" message={server_response.message} />);
      } else {
        setInfo(<Alert type="danger" message={server_response.message} />);
      }
    } else {
      setInfo(
        <Alert type="danger" message="Complete all fields and try again!!" />
      );
    }
  };

  return (
    <div
      className="modal fade add-vendor"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="myLargeModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title h4" id="myLargeModalLabel">
              Add Vendor
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div className="modal-body">
            {loading ? <Loader /> : info}
            <div className="form-group">
              <label className="form-label">
                Business Name <small style={{ color: "red" }}>* required</small>
              </label>
              <input
                type="text"
                value={business_name}
                onChange={(e) => setBusiness(e.target.value)}
                className="form-control"
                placeholder="Enter bussiness names"
              />
            </div>
            <div className="form-group">
              <label className="form-label">
                Phone <small style={{ color: "red" }}>* required</small>
              </label>
              <input
                type="text"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                className="form-control"
                placeholder="Enter phone number"
              />
            </div>
            <div className="form-group">
              <label className="form-label">
                Email <small style={{ color: "red" }}>* required</small>
              </label>
              <input
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="form-control"
                placeholder="Enter email address"
              />
            </div>
            <div className="form-group">
              <label className="form-label">
                Location <small style={{ color: "red" }}>* required</small>
              </label>
              <input
                type="text"
                value={location}
                onChange={(e) => setLocation(e.target.value)}
                className="form-control"
                placeholder="Enter bussiness location"
              />
            </div>
            <div className="form-group">
              <label className="form-label">Owners Name</label>
              <input
                type="text"
                value={owners_name}
                onChange={(e) => setOwner(e.target.value)}
                className="form-control"
                placeholder="Enter bussiness owner"
              />
            </div>
            <div className="form-group">
              <label className="form-label">
                Transfer Type <small style={{ color: "red" }}>* required</small>
              </label>
              <select
                type="text"
                value={type}
                onChange={(e) => setType(e.target.value)}
                className="form-control"
              >
                <option value=""> --- select transfer type --- </option>
                <option value="1">Instant</option>
                <option value="2">Batched</option>
                <option value="3">No Transfer</option>
              </select>
            </div>
            <div className="form-group">
              <label className="form-label">
                Bank <small style={{ color: "red" }}>* required</small>
              </label>
              <select
                type="text"
                value={bank_id}
                onChange={(e) => setBank(e.target.value)}
                className="form-control"
              >
                <option value=""> --- select bank --- </option>
                {banksList &&
                  Array.isArray(banksList) &&
                  banksList.map((bank, key) => (
                    <option key={key} value={bank.id}>
                      {bank.bank_name}
                    </option>
                  ))}
              </select>
            </div>
            <div className="form-group">
              <label className="form-label">
                Account Number{" "}
                <small style={{ color: "red" }}>* required</small>
              </label>
              <input
                type="text"
                value={account_no}
                onChange={(e) => setAccountno(e.target.value)}
                className="form-control"
                placeholder="Enter account Number"
              />
            </div>
            <div className="form-group">
              <label className="form-label">
                Account Name <small style={{ color: "red" }}>* required</small>
              </label>
              <input
                type="text"
                value={account_name}
                onChange={(e) => setAccountname(e.target.value)}
                className="form-control"
                placeholder="Enter account Name"
              />
            </div>
            <div className="form-group">
              <label className="form-label">Opening Branch</label>
              <input
                type="text"
                value={opening_branch}
                onChange={(e) => setOpeningbranch(e.target.value)}
                className="form-control"
                placeholder="Enter account opening branch"
              />
            </div>
            <div className="d-grid">
              <button
                onClick={registerVendor}
                className="btn btn-primary btn-block mt-2"
              >
                Register Vendor
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddVendor;
