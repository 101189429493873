import apiCall from "./apiCall";

export default {
  async addCustomer(vendor_id, name, contact, location, nin) {
    let data = {
      vendor_id: vendor_id,
      name: name,
      contact: contact,
      location: location,
      nin: nin,
    };
    let response = await apiCall("vendor/customer/register", data);

    return response;
  },
  async listCustomers(vendor_id) {
    let data = { vendor_id: vendor_id };

    let response = await apiCall("vendor/customers/list", data);
    return response;
  },
  async countCustomers(vendor_id) {
    let data = { vendor_id: vendor_id };

    let response = await apiCall("vendor/customers/count", data);
    return response;
  },
};
