import React, { useEffect, useState } from "react";
import ajaxUser from "../../util/remote/ajaxUser";
import functions from "../../util/functions";

function UsersCount() {
  const [count, setCount] = useState("...");
  const [user_id, setUs] = useState(functions.sessionGuard());
  const [data, setData] = useState({ page: "1" });

  useEffect(() => {
    countCustomers();
  }, [data]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      countCustomers();
    }, 5000);
    return () => clearInterval(intervalId); //This is important
  });

  const countCustomers = async () => {
    const server_response = await ajaxUser.countVendorUsers(user_id);
    if (server_response.status === "OK") {
      setCount(server_response.details);
    }
  };

  return (
    <div className="col-xl-3 col-sm-6">
      <a href="sysuser">
        <div className="card">
          <div className="card-body rounded border border-success bg-light-success">
            <div className="d-flex align-items-center">
              <div className="numbers flex-grow-1 pe-3">
                <p className="fw-600 mb-1 text-muted">Total Users</p>
                <h4 className="fw-700 mb-0 text-dark-black">{count}</h4>
              </div>
              <div className="icon-shape bg-success ">
                <i className="ti ti-users" />
              </div>
            </div>
          </div>
        </div>
      </a>
    </div>
  );
}

export default UsersCount;
