import React, { useContext, useState } from "react";
import Loader from "../Common/Loader";
import Alert from "../Common/Alert";
import ajaxUser from "../../util/remote/ajaxUser";
import AuthContext from "../../Context/AuthContext";
import SalesPointContext from "../../Context/SalesPointContext";
import functions from "../../util/functions";

function AddUser() {
  const [vendor_id, setVendorId] = useState(functions.getVendor());
  const [sales_point, setSalesPoint] = useState("");
  const [first_name, setFname] = useState("");
  const [last_name, setLname] = useState("");
  const [phone, setPhone] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [role_id, setRoleid] = useState("");
  const [user_role, setUsr] = useState(functions.getRoleId());

  const [loading, setLoading] = useState("");
  const [info, setInfo] = useState("");

  const { listVendorUsers } = useContext(AuthContext);
  const { salesPointsList } = useContext(SalesPointContext);

  const registerUser = async (e) => {
    if (
      first_name.length > 0 &&
      last_name.length > 0 &&
      phone.length > 0 &&
      username.length > 0 &&
      password.length > 0
    ) {
      let role = role_id;
      if (user_role === "2") {
        role = user_role;
      }
      if (role_id === "4" && sales_point.length < 0) {
        setInfo(
          <Alert type="danger" message="Complete all fields and try again!!" />
        );
      } else {
        setLoading(true);
        const server_response = await ajaxUser.registerUser(
          vendor_id,
          sales_point,
          first_name,
          last_name,
          username,
          password,
          phone,
          role
        );
        setLoading(false);

        if (server_response.status === "OK") {
          listVendorUsers();
          setFname("");
          setLname("");
          setUsername("");
          setPassword("");
          setPhone("");
          setRoleid("");
          setSalesPoint("");
          setInfo(<Alert type="success" message={server_response.message} />);
        } else {
          setInfo(<Alert type="danger" message={server_response.message} />);
        }
      }
    } else {
      setInfo(
        <Alert type="danger" message="Complete all fields and try again!!" />
      );
    }
  };

  return (
    <div
      className="modal fade add-user"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="myLargeModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title h4" id="myLargeModalLabel">
              Add System User
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div className="modal-body">
            {loading ? <Loader /> : info}

            {user_role !== "2" && (
              <div className="form-group">
                <label className="form-label">
                  User Type <small style={{ color: "red" }}>* required</small>
                </label>
                <select
                  type="text"
                  value={role_id}
                  onChange={(e) => setRoleid(e.target.value)}
                  className="form-control"
                >
                  <option value=""> --- select user type --- </option>
                  <option value="4"> Sales Representative </option>
                  <option value="3"> Admin </option>
                </select>
              </div>
            )}
            {role_id === "4" && (
              <div className="form-group">
                <label className="form-label">
                  Sales Point <small style={{ color: "red" }}>* required</small>
                </label>
                <select
                  type="text"
                  value={sales_point}
                  onChange={(e) => setSalesPoint(e.target.value)}
                  className="form-control"
                >
                  <option value=""> --- select sales point --- </option>
                  {salesPointsList &&
                    Array.isArray(salesPointsList) &&
                    salesPointsList.map((salespoint, key) => (
                      <option key={key} value={salespoint.id}>
                        {salespoint.sales_point}
                      </option>
                    ))}
                </select>
              </div>
            )}
            <div className="form-group">
              <label className="form-label">
                First Name <small style={{ color: "red" }}>* required</small>
              </label>
              <input
                type="text"
                value={first_name}
                onChange={(e) => setFname(e.target.value)}
                className="form-control"
                placeholder="Enter first name"
              />
            </div>
            <div className="form-group">
              <label className="form-label">
                Last Name <small style={{ color: "red" }}>* required</small>
              </label>
              <input
                type="text"
                value={last_name}
                onChange={(e) => setLname(e.target.value)}
                className="form-control"
                placeholder="Enter last name"
              />
            </div>
            <div className="form-group">
              <label className="form-label">
                Phone Number <small style={{ color: "red" }}>* required</small>
              </label>
              <input
                type="text"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                className="form-control"
                placeholder="Enter phone number"
              />
            </div>
            <div className="form-group">
              <label className="form-label">
                Username <small style={{ color: "red" }}>* required</small>
              </label>
              <input
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                className="form-control"
                placeholder="Enter username"
              />
            </div>
            <div className="form-group">
              <label className="form-label">
                Password <small style={{ color: "red" }}>* required</small>
              </label>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="form-control"
                placeholder="Enter password"
              />
            </div>
            <div className="d-grid">
              <button
                onClick={registerUser}
                className="btn btn-primary btn-block mt-2"
              >
                Add System User
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddUser;
