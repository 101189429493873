import React, { useEffect, useState } from "react";
import ajaxPayments from "../util/remote/ajaxPayments";
import functions from "../util/functions";

const PaymentsContext = React.createContext();

export const PaymentsConsumer = PaymentsContext.Consumer;

export const PaymentsProvider = (props) => {
  const [paymentsList, setPaymentsList] = useState([]);
  const [vendor_id, setPaymentsVendor] = useState(functions.getVendor());
  const [data, setData] = useState({ page: "1" });
  const [limit, setLimit] = useState(50);
  const [status, setStatus] = useState(null);

  useEffect(() => {
    listPayments();
  }, [data, status]);

  const listPayments = async () => {
    // console.log(status);
    const server_response = await ajaxPayments.listPayments(
      vendor_id,
      limit,
      status
    );

    if (server_response.status === "OK") {
      setPaymentsList(server_response.details);
    } else {
      // setPaymentsList("404");
    }
  };

  return (
    <PaymentsContext.Provider
      value={{
        setData,
        paymentsList,
        listPayments,
        setLimit,
        setStatus,
      }}
    >
      {props.children}
    </PaymentsContext.Provider>
  );
};

export default PaymentsContext;
