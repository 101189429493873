import apiCall from "./apiCall";

export default {
  async registerUser(
    vendor_id,
    sales_point_id,
    first_name,
    last_name,
    username,
    password,
    phone,
    role_id
  ) {
    let data = {
      vendor_id: vendor_id,
      sales_point_id: sales_point_id,
      first_name: first_name,
      last_name: last_name,
      username: username,
      password: password,
      role_id: role_id,
      phone: phone,
    };
    let response = await apiCall("vendor/user/register", data);
    return response;
  },
  async loginUser(username, password) {
    let data = {
      username: username,
      password: password,
    };
    let response = await apiCall("user/login", data);
    return response;
  },
  async getUserPermissionCodes(userId) {
    let data = {
      user_id: userId,
    };

    let response = await apiCall("user/permission/codes", data);
    return response;
  },
  async fetchVendorUsers(id) {
    let data = {
      user_id: id,
    };
    let response = await apiCall("vendor/users/list", data);

    return response;
  },
  async countVendorUsers(id) {
    let data = {
      user_id: id,
    };
    let response = await apiCall("vendor/users/count", data);

    return response;
  },
  async fetchSingleUser(id) {
    let data = {
      user_id: id,
    };
    let response = await apiCall("user/list/single", data);

    return response;
  },
};
