import React, { useEffect, useState } from "react";
import AppContainer from "../Components/Structure/AppContainer";
import Loader from "../Components/Common/Loader";
import { RenderSecure } from "../util/script/RenderSecure";
import Alert from "../Components/Common/Alert";
import functions from "../util/functions";
import ajaxPayments from "../util/remote/ajaxPayments";

function Deposits() {
  const [paymentsList, setPaymentsList] = useState([]);
  const [data, setData] = useState({ page: "1" });
  const [status, setStatus] = useState(3);
  const [loading, setLoading] = useState(false);
  const [info, setInfo] = useState("");
  const [vendor_id, setPaymentsVendor] = useState(functions.getVendor());
  const [limit, setLimit] = useState(500);

  useEffect(() => {
    listPayments(true);
  }, [data, status]);

  const listPayments = async (load) => {
    load && setLoading(true);
    const server_response = await ajaxPayments.listPayments(
      vendor_id,
      limit,
      status
    );
    setLoading(false);

    console.log(server_response.status);

    if (server_response.status === "OK") {
      setPaymentsList(server_response.details);
    } else {
      setPaymentsList("404");
      setInfo(<Alert type="danger" message={server_response.message} />);
    }
  };

  const setstatuss = (no) => {
    setStatus(no);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      listPayments(false);
    }, 2000);
    return () => clearInterval(intervalId);
  });

  return (
    <AppContainer title={"Deposit Statement"} href={"home"} parent={"Home"}>
      <div className="col-12">
        <div className="card table-card">
          <div className="card-header">
            <h4 style={{ float: "left" }}>
              {status == null && "All"}
              {status == 1 && "Pending"}
              {status == 2 && "Failed"}
              {status == 3 && "Successfull"} Payments
            </h4>
          </div>
          <div className="card-body">
            <div className="table-responsive">
              {loading && <Loader />}
              {!loading && (
                <table className="table" id="pc-dt-simple">
                  <thead>
                    <tr>
                      <th>#</th>
                      <RenderSecure code="ADD VENDOR">
                        <th>Vendor</th>
                      </RenderSecure>
                      <th>Customer</th>
                      <th>Amount</th>
                      <th>Service Point</th>
                      <th>Status</th>
                      <th>Date Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    {paymentsList &&
                      Array.isArray(paymentsList) &&
                      paymentsList.map((payment, key) => (
                        <tr key={key}>
                          <td>{key + 1}</td>
                          <RenderSecure code="ADD VENDOR">
                            <td>{payment.vendor.business_name}</td>
                          </RenderSecure>
                          <td>
                            {payment.customer
                              ? payment.customer.name
                              : "Unregistered"}
                            <br />
                            {payment.phone}
                          </td>

                          <td>{payment.cash_in}</td>
                          <td>
                            {payment.sales_point.sales_point}
                            <br />
                            {payment.vendor_user.first_name +
                              " " +
                              payment.vendor_user.last_name}
                          </td>
                          <td>
                            {payment.status == 1 ? (
                              <span className="badge bg-primary">Pending</span>
                            ) : payment.status == 3 ? (
                              <span className="badge bg-success">
                                Successfull
                              </span>
                            ) : (
                              <span className="badge bg-danger">Failed</span>
                            )}
                          </td>
                          <td>{payment.created_at.long_date}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
      </div>
    </AppContainer>
  );
}

export default Deposits;
