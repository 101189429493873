import React, { useEffect, useState } from "react";
import {
  Route,
  BrowserRouter as Router,
  Routes as Switch,
  Navigate,
} from "react-router-dom";

import SuperProvider from "./Context/SuperProvider";
import Dashboard from "./Pages/Dashboard";
import Login from "./Pages/Login";
import Customer from "./Pages/Customer";
import Vendor from "./Pages/Vendor";
import SalesPoint from "./Pages/SalesPoint";
import SystemUser from "./Pages/SystemUser";
import Payments from "./Pages/Payments";
import Banja from "./Pages/Banja";
import VendorUser from "./Pages/VendorUser";
import BankTransfers from "./Pages/BankTransfers";
import Deposits from "./Pages/Deposits";
import Pendings from "./Pages/Pendings";
import Faileds from "./Pages/Faileds";
import Collectors from "./Pages/Collectors";
import CollectorProfile from "./Pages/CollectorProfile";

function App() {
  const [loggedIn, setLoggedIn] = useState(false);

  function checkLogin() {
    if (!window.localStorage.getItem("vendor_pay@user")) {
      setLoggedIn(false);
    } else {
      setLoggedIn(true);
    }
  }
  useEffect(() => {
    checkLogin();
  }, []);

  return (
    <SuperProvider>
      <Router forceRefresh={false}>
        <Switch>
          {!loggedIn && (
            <>
              <Route path="*" element={<Login />} />
              <Route path="/login" element={<Login />} />
              <Route
                path="/"
                element={
                  !loggedIn ? <Navigate replace to="/login" /> : <Dashboard />
                }
              />
            </>
          )}
          {loggedIn && (
            <>
              <Route
                path="/"
                element={
                  !loggedIn ? <Navigate replace to="/login" /> : <Dashboard />
                }
              />
              <Route
                path="/login"
                element={loggedIn ? <Navigate replace to="/" /> : <Login />}
              />
              <Route path="/" element={<Dashboard />} />
              <Route path="/home" element={<Dashboard />} />
              <Route path="/customer" element={<Customer />} />
              <Route path="/vendor" element={<Vendor />} />
              <Route path="/collector" element={<Collectors />} />
              <Route path="/salespoint" element={<SalesPoint />} />
              <Route path="/sysuser" element={<SystemUser />} />
              <Route path="/payment" element={<Payments />} />
              <Route path="/banja" element={<Banja />} />
              <Route path="/banktransfers" element={<BankTransfers />} />
              <Route path="/deposits" element={<Deposits />} />
              <Route path="/pendings" element={<Pendings />} />
              <Route path="/faileds" element={<Faileds />} />
              <Route path="/vendor_users/:id" element={<VendorUser />} />
              <Route
                path="/collector_profile/:id"
                element={<CollectorProfile />}
              />
            </>
          )}
        </Switch>
      </Router>
    </SuperProvider>
  );
}

export default App;
