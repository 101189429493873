import React from "react";
import { AuthProvider } from "./AuthContext";
import { ThemeProvider } from "./ThemeContext";
import { CustomerProvider } from "./CustomerContext";
import { VendorProvider } from "./VendorContext";
import { SalesPointProvider } from "./SalesPointContext";
import { PaymentsProvider } from "./PaymentsContext";
import { BanjaProvider } from "./BanjaContext";

const SuperProvider = (props) => {
  return (
    <ThemeProvider>
      <AuthProvider>
        <CustomerProvider>
          <VendorProvider>
            <SalesPointProvider>
              <PaymentsProvider>
                <BanjaProvider>{props.children}</BanjaProvider>
              </PaymentsProvider>
            </SalesPointProvider>
          </VendorProvider>
        </CustomerProvider>
      </AuthProvider>
    </ThemeProvider>
  );
};

export default SuperProvider;
