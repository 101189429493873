import React, { useContext, useState } from "react";
import Loader from "../Common/Loader";
import Alert from "../Common/Alert";
import ajaxCustomer from "../../util/remote/ajaxCustomer";
import CustomerContext from "../../Context/CustomerContext";
import functions from "../../util/functions";

function AddCustomer() {
  const [vendor_id, setVendorId] = useState(functions.getVendor());
  const [name, setName] = useState("");
  const [contact, setContact] = useState("");
  const [location, setLocation] = useState("");
  const [nin, setNin] = useState("");

  const [loading, setLoading] = useState("");
  const [info, setInfo] = useState("");

  const { listCustomers } = useContext(CustomerContext);

  const addUser = async (e) => {
    if (name.length > 0 && contact.length > 0 && location.length > 0) {
      setLoading(true);
      const server_response = await ajaxCustomer.addCustomer(
        vendor_id,
        name,
        contact,
        location,
        nin
      );
      setLoading(false);

      if (server_response.status === "OK") {
        listCustomers();
        setName("");
        setContact("");
        setLocation("");
        setNin("");
        setInfo(<Alert type="success" message={server_response.message} />);
      } else {
        setInfo(<Alert type="danger" message={server_response.message} />);
      }
    } else {
      setInfo(
        <Alert type="danger" message="Complete all fields and try again!!" />
      );
    }
  };

  return (
    <div
      className="modal fade add-customer"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="myLargeModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title h4" id="myLargeModalLabel">
              Add Customer
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div className="modal-body">
            {loading ? <Loader /> : info}
            <div className="form-group">
              <label className="form-label">
                Name <small style={{ color: "red" }}>* required</small>
              </label>
              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="form-control"
                placeholder="Enter customer names"
              />
            </div>
            <div className="form-group">
              <label className="form-label">
                Contact <small style={{ color: "red" }}>* required</small>
              </label>
              <input
                type="text"
                value={contact}
                onChange={(e) => setContact(e.target.value)}
                className="form-control"
                placeholder="Enter customer contacts"
              />
            </div>
            <div className="form-group">
              <label className="form-label">
                Location <small style={{ color: "red" }}>* required</small>
              </label>
              <input
                type="text"
                value={location}
                onChange={(e) => setLocation(e.target.value)}
                className="form-control"
                placeholder="Enter customer location"
              />
            </div>
            <div className="form-group">
              <label className="form-label">Nin</label>
              <input
                type="text"
                value={nin}
                onChange={(e) => setNin(e.target.value)}
                className="form-control"
                placeholder="Enter customer nin"
              />
            </div>
            <div className="d-grid">
              <button
                onClick={addUser}
                className="btn btn-primary btn-block mt-2"
              >
                Add Customer
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddCustomer;
