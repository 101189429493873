import React, { useContext, useState } from "react";
import Loader from "../Common/Loader";
import Alert from "../Common/Alert";
import ajaxSalesPoints from "../../util/remote/ajaxSalesPoints";
import SalesPointContext from "../../Context/SalesPointContext";
import functions from "../../util/functions";

function UpdateSalesPoint(props) {
  const [vendor_id, setVendor] = useState(functions.getVendor());
  const [sales_point, setSalesPoint] = useState(props.salespoint.sales_point);
  const [sales_point_id, setSalesPointID] = useState(props.salespoint.id);
  // console.log(props);
  const [loading, setLoading] = useState("");
  const [info, setInfo] = useState("");

  const { setVendorID, listSalesPoint } = useContext(SalesPointContext);

  const updateSalesPoint = async (e) => {
    if (sales_point.length > 0) {
      setLoading(true);
      const server_response = await ajaxSalesPoints.updateSalesPoint(
        sales_point_id,
        sales_point
      );
      setLoading(false);

      if (server_response.status === "OK") {
        setVendorID(vendor_id);
        listSalesPoint();
        setSalesPoint("");
        getSalesPoint();
        setInfo(<Alert type="success" message={server_response.message} />);
      } else {
        setInfo(<Alert type="danger" message={server_response.message} />);
      }
    } else {
      setInfo(
        <Alert type="danger" message="Complete all fields and try again!!" />
      );
    }
  };

  const getSalesPoint = async (e) => {
    setLoading(true);
    const server_response = await ajaxSalesPoints.getSalesPoint(sales_point_id);
    setLoading(false);

    if (server_response.status === "OK") {
      setVendorID(vendor_id);
      listSalesPoint();
      setSalesPoint(server_response.details.sales_point);
    }
  };

  return (
    <div
      className={`modal fade update-salespoint${sales_point_id}`}
      tabIndex={-1}
      role="dialog"
      aria-labelledby="myLargeModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title h4" id="myLargeModalLabel">
              Update Sales Point
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div className="modal-body">
            {loading ? <Loader /> : info}
            <div className="form-group">
              <label className="form-label">
                Sales Point <small style={{ color: "red" }}>* required</small>
              </label>
              <input
                type="text"
                value={sales_point}
                onChange={(e) => setSalesPoint(e.target.value)}
                className="form-control"
                placeholder="Enter sales point"
              />
            </div>

            <div className="d-grid">
              <button
                onClick={updateSalesPoint}
                className="btn btn-primary btn-block mt-2"
              >
                Update Sales Point
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UpdateSalesPoint;
