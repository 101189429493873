import React, { useEffect, useState } from "react";
import ajaxVendor from "../util/remote/ajaxVendor";

const VendorContext = React.createContext();

export const VendorConsumer = VendorContext.Consumer;

export const VendorProvider = (props) => {
  const [vendorsList, setVendirsList] = useState([]);
  const [banksList, setBanksList] = useState([]);
  const [data, setData] = useState({ page: "1" });

  useEffect(() => {
    listVendors();
    listBanks();
  }, [data]);

  const listVendors = async () => {
    const server_response = await ajaxVendor.listVendors();

    if (server_response.status === "OK") {
      setVendirsList(server_response.details);
    } else {
      setVendirsList("404");
    }
  };

  const listBanks = async () => {
    const server_response = await ajaxVendor.listBanks();

    if (server_response.status === "OK") {
      setBanksList(server_response.details);
    } else {
      setBanksList("404");
    }
  };

  return (
    <VendorContext.Provider
      value={{
        setData,
        vendorsList,
        listVendors,
        banksList,
      }}
    >
      {props.children}
    </VendorContext.Provider>
  );
};

export default VendorContext;
