import React, { useEffect, useState } from "react";
import ajaxCustomer from "../util/remote/ajaxCustomer";
import functions from "../util/functions";

const CustomerContext = React.createContext();

export const CustomerConsumer = CustomerContext.Consumer;

export const CustomerProvider = (props) => {
  const [customerList, setCustomerList] = useState([]);
  const [vendor_id, setVendorId] = useState(functions.getVendor());
  const [data, setData] = useState({ page: "1" });

  useEffect(() => {
    listCustomers();
  }, [data]);

  const listCustomers = async () => {
    const server_response = await ajaxCustomer.listCustomers(vendor_id);

    if (server_response.status === "OK") {
      setCustomerList(server_response.details);
    } else {
      setCustomerList("404");
    }
  };

  return (
    <CustomerContext.Provider
      value={{
        setData,
        customerList,
        listCustomers,
      }}
    >
      {props.children}
    </CustomerContext.Provider>
  );
};

export default CustomerContext;
