import React, { useContext, useEffect, useState } from "react";
import AppContainer from "../Components/Structure/AppContainer";
import Loader from "../Components/Common/Loader";
import Alert from "../Components/Common/Alert";
import AuthContext from "../Context/AuthContext";
import AddUser from "../Components/Modal/AddUser";
import dictionary from "../util/dictionary";
import functions from "../util/functions";

function SystemUser() {
  const { userList } = useContext(AuthContext);
  const [user_role, setUsr] = useState(functions.getRoleId());
  // console.log(userList);

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const loadingInterval = setInterval(() => {
      setLoading(false);
    }, dictionary._loadingInterval);
    return () => clearInterval(loadingInterval); //This is important
  });

  return (
    <AppContainer title={"System Users"} href={"home"} parent={"Home"}>
      <div className="col-12">
        <div className="card table-card">
          <div className="card-header">
            <h4 style={{ float: "left" }}>System Users</h4>
            <button
              type="button"
              className="btn  btn-primary mr-2 mb-2"
              data-bs-toggle="modal"
              data-bs-target=".add-user"
              style={{ float: "right" }}
            >
              Add System User
            </button>
            <AddUser />
          </div>
          <div className="card-body">
            {loading && <Loader />}
            {!loading && (
              <div className="table-responsive">
                <table className="table" id="pc-dt-simple">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Name</th>
                      <th>Username</th>
                      {user_role !== "2" && (
                        <>
                          <th>User No</th>
                          <th>Sales Point</th>
                        </>
                      )}
                      <th>Contact</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!userList && <Loader />}
                    {userList && userList === "404" && (
                      <Alert type="danger" message="No system users found" />
                    )}
                    {userList &&
                      Array.isArray(userList) &&
                      userList.map((user, key) => (
                        <tr key={key}>
                          <td>{key + 1}</td>
                          <td>{user.first_name + " " + user.last_name}</td>
                          <td>{user.username}</td>
                          {user_role !== "2" && (
                            <>
                              <td>{user.vendor_user_no}</td>
                              <td>{user.sales_point.sales_point}</td>
                            </>
                          )}
                          <td>{user.phone}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
    </AppContainer>
  );
}

export default SystemUser;
