import React, { useEffect, useState } from "react";
import dictionary from "../util/dictionary";

const ThemeContext = React.createContext();

export const ThemeConsumer = ThemeContext.Consumer;

export const ThemeProvider = (props) => {
  const [refreshRate, setRefreshRate] = useState(1);

  useEffect(() => {
    setTimeout(() => {
      setRefreshRate(refreshRate + 1);
    }, dictionary._uiRefreshRate);
  }, [refreshRate]);

  return <ThemeContext.Provider>{props.children}</ThemeContext.Provider>;
};

export default ThemeContext;
