import React, { useContext, useEffect, useState } from "react";
import AuthContext from "../../Context/AuthContext";
import Loader from "../Common/Loader";
import dictionary from "../../util/dictionary";

function UserTile() {
  const { user, userId, getUserInfo } = useContext(AuthContext);

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const loadingInterval = setInterval(() => {
      setLoading(false);
    }, dictionary._loadingInterval);
    return () => clearInterval(loadingInterval); //This is important
  });

  useEffect(() => {
    getUserInfo();
  }, [userId]);

  var myDate = new Date();
  var hours = myDate.getHours();
  var greet;

  if (hours < 12) greet = "morning";
  else if (hours >= 12 && hours <= 17) greet = "afternoon";
  else if (hours >= 17 && hours <= 24) greet = "evening";

  return (
    <div className="col-12">
      <div className="card">
        <div className="card-body bg-primary rounded-3">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-12">
              <div className="d-lg-flex justify-content-between align-items-center ">
                <div className="d-md-flex align-items-center">
                  <img
                    src="assets/images/user/avatar-2.jpg"
                    alt="Image"
                    className="rounded-circle avatar avatar-xl"
                  />
                  <div className="ms-md-4 mt-3">
                    <h2 className="text-white fw-600 mb-1">
                      Good {greet}, <br />
                      {loading && <Loader />}
                      {!loading && (
                        <>
                          {user.first_name} {user.last_name}
                        </>
                      )}
                    </h2>
                    <p className="text-white">
                      {" "}
                      Here is what’s happening in your bussiness today:
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserTile;
