import functions from "./functions";

const dictionary = {
  _completeFields: "Complete all fields and try again",
  _toastTimeOut: 5000,
  _loadingInterval: 2000,
  _uiRefreshRate: 1000,
  apiHost: functions.getHost(),
};

export default dictionary;
