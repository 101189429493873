import React from "react";

function Footer() {
  return (
    <footer className="app-footer">
      <div className="footer-wrapper">
        <div className="py-1">
          <span className="text-muted">
            © 2023, vendor pay. Powered by{" "}
            <a target="_blank" href="https://quickpostug.com">QUICKPOST UGANDA LIMITED</a>
          </span>
        </div>
        <div className="py-1">
          <ul className="list-inline m-0">
            <li className="list-inline-item">
              <a className="link-secondary" href="javascript:">
                About Us
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
