import React, { useEffect, useState } from "react";
import dictionary from "../../util/dictionary";
import functions from "../../util/functions";
import ajaxVendor from "../../util/remote/ajaxVendor";
import Alert from "../Common/Alert";
import Loader from "../Common/Loader";

function TopBar() {
  const onLogout = () => {
    localStorage.removeItem("vendor_pay@user");
    window.location.reload();
  };

  const onShowMenu = () => {
    let nav = document.getElementById("navvvv");
    // console.log(nav);
    nav.classList.add("mob-sidebar-active");
  };

  const [codeList, setCodeList] = useState([]);
  const [vendor_id, setVe] = useState(functions.getVendor());
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    listVendorCodes();
  }, [vendor_id]);

  const listVendorCodes = async () => {
    if (!vendor_id) {
      return false;
    }
    setLoading(true);
    const server_response = await ajaxVendor.listVendorCodes(vendor_id);
    // console.log(server_response);
    setLoading(false);
    if (server_response.status === "OK") {
      setCodeList(server_response.details);
    } else {
      setCodeList("404");
    }
  };

  return (
    <header className="site-header">
      <div className="header-wrapper">
        <div className="me-auto flex-grow-1 d-flex align-items-center">
          <ul className="list-unstyled header-menu-nav">
            <li onClick={onShowMenu} className="hdr-itm mob-hamburger">
              <a href="#!" className="app-head-link" id="mobile-collapse">
                <div className="hamburger hamburger-arrowturn">
                  <div className="hamburger-box">
                    <div className="hamburger-inner" />
                  </div>
                </div>
              </a>
            </li>
          </ul>
          <div className="d-none d-md-none d-lg-block header-search ms-3">
            <form action="#">
              <div className="input-group ">
                <input
                  className="form-control rounded-3"
                  type="search"
                  defaultValue
                  id="searchInput"
                  placeholder="Search"
                />
                <div className="search-btn">
                  <button
                    className="btn p-0 rounded-0 rounded-end"
                    type="button"
                  >
                    <i data-feather="search" />
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <nav className="ms-auto">
          <ul className="header-menu-nav list-unstyled">
            {vendor_id && (
              <li className="hdr-itm dropdown ntf-dropdown">
                <a
                  href="#"
                  className="app-head-link dropdown-toggle no-caret"
                  data-bs-toggle="dropdown"
                  role="button"
                  aria-haspopup="false"
                  aria-expanded="false"
                >
                  <i className="ti ti-users" />
                  <span className="bg-success h-dots" />
                </a>
                <div className="dropdown-menu header-dropdown  dropdown-menu-end">
                  <div className="notification-header">
                    <h5 className="m-0">Vendor Numbers</h5>
                    <a href="/sysuser" className="btn  btn-outline-success">
                      View Users
                    </a>
                  </div>
                  <div className="notification-list">
                    <div className="notification-body">
                      {loading && <Loader />}
                      {codeList && codeList === "404" && (
                        <Alert
                          message={"No vendor user numbers found"}
                          type="danger"
                        />
                      )}
                      {codeList &&
                        Array.isArray(codeList) &&
                        codeList.map((code, key) => (
                          <div
                            key={key}
                            style={{
                              borderBlockColor: "grey",
                              borderBottomWidth: 1,
                            }}
                            className="d-flex align-items-start my-3"
                          >
                            <div className="flex-grow-1">
                              <div className="d-flex align-items-start justify-content-between mb-2">
                                <h5 className="mb-0">{code.vendor_uder_no}</h5>
                              </div>
                              <div className="d-flex align-items-end justify-content-between">
                                <p className="mb-0 text-muted">
                                  {code.first_name + " " + code.last_name}
                                </p>
                                <span className="text-sm ms-2 text-nowrap">
                                  {code.sales_point &&
                                    code.sales_point.sales_point}
                                </span>
                              </div>
                            </div>
                            <hr />
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </li>
            )}
            <li className="hdr-itm dropdown ntf-dropdown">
              <a
                href="#"
                className="app-head-link dropdown-toggle no-caret"
                data-bs-toggle="dropdown"
                role="button"
                aria-haspopup="false"
                aria-expanded="false"
              >
                <i className="ti ti-bell" />
                <span className="bg-danger h-dots" />
              </a>
              <div className="dropdown-menu header-dropdown  dropdown-menu-end">
                <div className="notification-header">
                  <h5 className="m-0">Notification</h5>
                  <a href="#" className="btn  btn-outline-danger">
                    Delet All
                  </a>
                </div>
                <div className="notification-list">
                  <div className="notification-body">
                    <div className="d-flex align-items-start my-3">
                      <div className="flex-grow-1">
                        <div className="d-flex align-items-start justify-content-between mb-2">
                          <a href="#">
                            <h5 className="mb-0">
                              Desktop notification turned on
                            </h5>
                          </a>
                          <a href="#" className="text-hover-danger">
                            <i data-feather="x" />
                          </a>
                        </div>
                        <div className="d-flex align-items-end justify-content-between">
                          <p className="mb-0 text-muted">
                            Now you will receive desktop notifications
                          </p>
                          <span className="text-sm ms-2 text-nowrap">
                            1 min ago
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="notification-footer">
                  <div className="d-grid">
                    <a href="#" className="btn  btn-outline-secondary">
                      View all
                    </a>
                  </div>
                </div>
              </div>
            </li>
            <li className="hdr-itm dropdown user-dropdown ">
              <a
                className="app-head-link dropdown-toggle no-caret me-0"
                data-bs-toggle="dropdown"
                href="#"
                role="button"
                aria-haspopup="false"
                aria-expanded="false"
              >
                <span className="avtar">
                  <img src={`${dictionary.apiHost}img/avatar-2.jpg`} alt />
                </span>
              </a>
              <div className="dropdown-menu header-dropdown">
                <ul className="p-0">
                  <li className="dropdown-item ">
                    <a href="#" className="drp-link">
                      Edit Profile
                    </a>
                  </li>
                  <hr className="dropdown-divider" />
                  <li className="dropdown-item ">
                    <a href="#" onClick={onLogout} className="drp-link">
                      <i data-feather="log-out" />
                      <span>Logout</span>
                    </a>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
}

export default TopBar;
