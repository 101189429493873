import React from "react";

import TopBar from "./TopBar";
import SideBar from "./SideBar";
import Footer from "./Footer";

function AppContainer(props) {
  return (
    <>
      {/* /// to bar */}
      <TopBar />

      {/* //side bar */}
      <SideBar />

      <div className="page-content-wrapper">
        <div className="content-container">
          <div className="page-content">
            <div className="content-header">
              <h1 className="mb-0">{props.title}</h1>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href={"/" + props.href}>{props.parent}</a>
                </li>
                <li className="breadcrumb-item">{props.title}</li>
              </ul>
            </div>
            <div className="row">
              <div className="col-12">
                {/* ///main body */}
                {props.children}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ///footer */}
      <Footer />
    </>
  );
}

export default AppContainer;
