import React, { useContext, useEffect, useState } from "react";
import AppContainer from "../Components/Structure/AppContainer";
import AddCustomer from "../Components/Modal/AddCustomer";
import CustomerContext from "../Context/CustomerContext";
import Loader from "../Components/Common/Loader";
import Alert from "../Components/Common/Alert";
import dictionary from "../util/dictionary";

function Customer() {
  const { customerList } = useContext(CustomerContext);
  // console.log(customerList);

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const loadingInterval = setInterval(() => {
      setLoading(false);
    }, dictionary._loadingInterval);
    return () => clearInterval(loadingInterval); //This is important
  });

  return (
    <AppContainer title={"Customers"} href={"home"} parent={"Home"}>
      <div className="col-12">
        <div className="card table-card">
          <div className="card-header">
            <h4 style={{ float: "left" }}>Customers</h4>
            <button
              type="button"
              className="btn  btn-primary mr-2 mb-2"
              data-bs-toggle="modal"
              data-bs-target=".add-customer"
              style={{ float: "right" }}
            >
              Add Customer
            </button>
            <AddCustomer />
          </div>
          <div className="card-body">
            {loading && <Loader />}
            {!loading && (
              <div className="table-responsive">
                <table className="table" id="pc-dt-simple">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Vendor</th>
                      <th>Customer</th>
                      <th>Contact</th>
                      <th>Location</th>
                      <th>NIN</th>
                      <th>Created At</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!customerList && <Loader />}
                    {customerList && customerList === "404" && (
                      <Alert type="danger" message="No customers found" />
                    )}
                    {customerList &&
                      Array.isArray(customerList) &&
                      customerList.map((customer, key) => (
                        <tr key={key}>
                          <td>{key + 1}</td>
                          <td>{customer.vendor.business_name}</td>
                          <td>{customer.name}</td>
                          <td>{customer.contact}</td>
                          <td>{customer.location}</td>
                          <td>{customer.nin}</td>
                          <td>{customer.created_at.long_date}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
    </AppContainer>
  );
}

export default Customer;
