import React, { useEffect, useState } from "react";
import functions from "../util/functions";
import ajaxUser from "../util/remote/ajaxUser";

const AuthContext = React.createContext();

export const AuthConsumer = AuthContext.Consumer;

export const AuthProvider = (props) => {
  const [userId, setUserId] = useState(functions.sessionGuard());
  const [permissionLists, setPermissionList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [user, setUser] = useState([]);

  useEffect(() => {
    getUserAccess();
    listVendorUsers();
    getUserInfo();
  }, [userId]);

  const getUserAccess = async () => {
    if (!userId) {
      return false;
    }

    // console.log(userId);

    const server_response = await ajaxUser.getUserPermissionCodes(userId);

    // console.log(server_response);

    if (server_response.status === "OK") {
      setPermissionList(server_response.details);
    } else {
      // alert("Something went wrong loading YEXP, check your connection or contact system admin")
    }
  };

  const listVendorUsers = async () => {
    if (!userId) {
      return false;
    }
    const server_response = await ajaxUser.fetchVendorUsers(userId);

    if (server_response.status === "OK") {
      setUserList(server_response.details);
    } else {
      setUserList("404");
    }
  };

  const getUserInfo = async () => {
    const server_response = await ajaxUser.fetchSingleUser(userId);

    if (server_response.status === "OK") {
      setUser(server_response.details);
    } else {
      // alert("Something went wrong loading YEXP, check your connection or contact system admin")
    }
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        userId,
        permissionLists,
        userList,
        listVendorUsers,
        getUserInfo,
        getUserAccess,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
