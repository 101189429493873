import React, { useContext, useEffect, useState } from "react";
import AppContainer from "../Components/Structure/AppContainer";
import Loader from "../Components/Common/Loader";
import Alert from "../Components/Common/Alert";
import AddVendorUser from "../Components/Modal/AddVendorUser";
import { useParams } from "react-router-dom";
import ajaxVendor from "../util/remote/ajaxVendor";

function VendorUser() {
  const { id } = useParams();
  const [userList2, setUserlist2] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    listVendorUsers();
  }, [id]);

  const listVendorUsers = async () => {
    setLoading(true);
    const server_response = await ajaxVendor.listVendorUsers(id);
    setLoading(false);
    if (server_response.status === "OK") {
      setUserlist2(server_response.details);
    } else {
      setUserlist2("404");
    }
  };

  return (
    <AppContainer title={"Vendor Users"} href={"home"} parent={"Home"}>
      <div className="col-12">
        <div className="card table-card">
          <div className="card-header">
            <h4 style={{ float: "left" }}>Vendor Users</h4>
            <button
              type="button"
              className="btn  btn-primary mr-2 mb-2"
              data-bs-toggle="modal"
              data-bs-target=".add-vendor"
              style={{ float: "right" }}
            >
              Add Vendor User
            </button>
            <AddVendorUser vendor_id={id} list={listVendorUsers} />
          </div>
          <div className="card-body">
            {loading && <Loader />}
            {!loading && (
              <div className="table-responsive">
                <table className="table" id="pc-dt-simple">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Name</th>
                      <th>User No</th>
                      <th>Sales Point</th>
                      <th>Contact</th>
                    </tr>
                  </thead>
                  <tbody>
                    {userList2 && userList2 === "404" && (
                      <Alert type="danger" message="No system users found" />
                    )}
                    {userList2 &&
                      Array.isArray(userList2) &&
                      userList2.map((user, key) => (
                        <tr key={key}>
                          <td>{key + 1}</td>
                          <td>{user.first_name + " " + user.last_name}</td>
                          <td>{user.vendor_user_no}</td>
                          <td>{user.sales_point.sales_point}</td>
                          <td>{user.phone}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
    </AppContainer>
  );
}

export default VendorUser;
