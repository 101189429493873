import React, { useEffect, useState } from "react";
import ajaxBanja from "../util/remote/ajaxBanja";
import functions from "../util/functions";

const BanjaContext = React.createContext();

export const BanjaConsumer = BanjaContext.Consumer;

export const BanjaProvider = (props) => {
  const [banjasList, setBanjasList] = useState([]);
  const [vendor_id, setBanjasVendor] = useState(functions.getVendor());
  const [data, setData] = useState({ page: "1" });

  useEffect(() => {
    listBanjas();
  }, [data]);

  const listBanjas = async () => {
    const server_response = await ajaxBanja.listBanjas(vendor_id);

    if (server_response.status === "OK") {
      setBanjasList(server_response.details);
    } else {
      setBanjasList("404");
    }
  };

  return (
    <BanjaContext.Provider
      value={{
        setData,
        banjasList,
        listBanjas,
      }}
    >
      {props.children}
    </BanjaContext.Provider>
  );
};

export default BanjaContext;
