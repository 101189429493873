import React, { useContext, useEffect, useState } from "react";
import AppContainer from "../Components/Structure/AppContainer";
import Loader from "../Components/Common/Loader";
import Alert from "../Components/Common/Alert";
import dictionary from "../util/dictionary";
import BanjaContext from "../Context/BanjaContext";
import AddBanja from "../Components/Modal/AddBanja";
import { RenderSecure } from "../util/script/RenderSecure";

function Banja() {
  const { banjasList } = useContext(BanjaContext);
  // console.log(customerList);

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const loadingInterval = setInterval(() => {
      setLoading(false);
    }, dictionary._loadingInterval);
    return () => clearInterval(loadingInterval); //This is important
  });

  return (
    <AppContainer title={"Customer Debts"} href={"home"} parent={"Home"}>
      <div className="col-12">
        <div className="card table-card">
          <div className="card-header">
            <h4 style={{ float: "left" }}>Customer Debts</h4>
            <RenderSecure code="ADD CREDITS">
              <button
                type="button"
                className="btn  btn-primary mr-2 mb-2"
                data-bs-toggle="modal"
                data-bs-target=".add-banja"
                style={{ float: "right" }}
              >
                Add Customer Debt
              </button>
              <AddBanja />
            </RenderSecure>
          </div>
          <div className="card-body">
            {loading && <Loader />}
            {!loading && (
              <div className="table-responsive">
                <table className="table" id="pc-dt-simple">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Customer</th>
                      <th>Amount</th>
                      <th>Served By</th>
                      <th>Memo</th>
                      <th>Added By</th>
                      <th>Date Added</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!banjasList && <Loader />}
                    {banjasList && banjasList === "404" && (
                      <Alert
                        type="danger"
                        message="No customer credits found"
                      />
                    )}
                    {banjasList &&
                      Array.isArray(banjasList) &&
                      banjasList.map((banja, key) => (
                        <tr key={key}>
                          <td>{key + 1}</td>
                          <td>
                            {banja.customer.name}
                            <br />
                            {banja.phone}
                          </td>
                          <td>{banja.credit}</td>
                          <td>
                            {banja.sales_point.sales_point}
                            <br />
                            {banja.vendor_user.first_name +
                              " " +
                              banja.vendor_user.last_name}
                          </td>
                          <td>{banja.memo}</td>
                          <td>
                            {banja.added_by.first_name +
                              " " +
                              banja.added_by.last_name}
                          </td>
                          <td>{banja.date_added.short_date}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
    </AppContainer>
  );
}

export default Banja;
