import apiCall from "./apiCall";

export default {
  async listPayments(vendor_id, limit, status) {
    let data = { vendor_id: vendor_id, limit: limit, status: status };

    let response = await apiCall("payments/list", data);
    return response;
  },
  async sumPayments(vendor_id) {
    let data = { vendor_id: vendor_id };

    let response = await apiCall("payments/total", data);
    return response;
  },
};
