import React, { useContext, useEffect, useState } from "react";
import AppContainer from "../Components/Structure/AppContainer";
import Loader from "../Components/Common/Loader";
import Alert from "../Components/Common/Alert";
import SalesPointContext from "../Context/SalesPointContext";
import AddSalesPoint from "../Components/Modal/AddSalesPoint";
import dictionary from "../util/dictionary";
import UpdateSalesPoint from "../Components/Update/UpdateSalesPoint";

function SalesPoint() {
  const { salesPointsList } = useContext(SalesPointContext);
  // console.log(salesPointsList);

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const loadingInterval = setInterval(() => {
      setLoading(false);
    }, dictionary._loadingInterval);
    return () => clearInterval(loadingInterval); //This is important
  });

  return (
    <AppContainer title={"Sales Points"} href={"home"} parent={"Home"}>
      <div className="col-12">
        <div className="card table-card">
          <div className="card-header">
            <h4 style={{ float: "left" }}>Sales Points</h4>
            <button
              type="button"
              className="btn  btn-primary mr-2 mb-2"
              data-bs-toggle="modal"
              data-bs-target=".add-customer"
              style={{ float: "right" }}
            >
              Add Sales Point
            </button>
            <AddSalesPoint />
          </div>
          <div className="card-body">
            {loading && <Loader />}
            {!loading && (
              <div className="table-responsive">
                <table className="table" id="pc-dt-simple">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Sales Point</th>
                      <th>Edit</th>
                      <th>Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!salesPointsList && <Loader />}
                    {salesPointsList && salesPointsList === "404" && (
                      <Alert type="danger" message="No customers found" />
                    )}
                    {salesPointsList &&
                      Array.isArray(salesPointsList) &&
                      salesPointsList.map((salespoint, key) => (
                        <tr key={key}>
                          <td>{key + 1}</td>
                          <td>{salespoint.sales_point}</td>
                          <td>
                            <button
                              data-bs-toggle="modal"
                              data-bs-target={`.update-salespoint${salespoint.id}`}
                              className="btn btn-sm btn-primary me-2"
                            >
                              <i className="ti ti-pencil"></i>Edit
                            </button>
                            <UpdateSalesPoint salespoint={salespoint} />
                          </td>
                          <td>
                            <button className="btn btn-sm btn-danger">
                              <i className="ti ti-x"></i>Delete
                            </button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
    </AppContainer>
  );
}

export default SalesPoint;
