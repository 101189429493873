import React, { useContext, useState } from "react";
import Loader from "../Common/Loader";
import Alert from "../Common/Alert";
import BanjaContext from "../../Context/BanjaContext";
import ajaxBanja from "../../util/remote/ajaxBanja";
import functions from "../../util/functions";
import CustomerContext from "../../Context/CustomerContext";
import AuthContext from "../../Context/AuthContext";

function AddBanja() {
  const [vendor_id, setVendorId] = useState(functions.getVendor());
  const [added_by, setAddedBy] = useState(functions.sessionGuard());
  const [customer_id, setCustomerId] = useState("");
  const [vendor_user_id, setVendorUserId] = useState("");
  const [credit, setCredit] = useState("");
  const [memo, setMemo] = useState("");

  const today = new Date();
  const numberOfDaysToAdd = 0;
  const date = today.setDate(today.getDate() + numberOfDaysToAdd);
  const defaultValue = new Date(date).toISOString().split("T")[0]; // yyyy-mm-dd

  const [date_added, setDateAdded] = useState(defaultValue);

  const [loading, setLoading] = useState("");
  const [info, setInfo] = useState("");

  const { listBanjas } = useContext(BanjaContext);
  const { customerList } = useContext(CustomerContext);
  const { userList } = useContext(AuthContext);

  const registerBanja = async (e) => {
    if (
      customer_id.length > 0 &&
      vendor_user_id.length > 0 &&
      credit.length > 0 &&
      date_added.length > 0
    ) {
      setLoading(true);
      const server_response = await ajaxBanja.registerBanja(
        vendor_id,
        customer_id,
        vendor_user_id,
        credit,
        memo,
        added_by,
        date_added
      );
      setLoading(false);

      if (server_response.status === "OK") {
        listBanjas();
        setCustomerId("");
        setVendorUserId("");
        setCredit("");
        setMemo("");
        setDateAdded("");
        setInfo(<Alert type="success" message={server_response.message} />);
      } else {
        setInfo(<Alert type="danger" message={server_response.message} />);
      }
    } else {
      setInfo(
        <Alert type="danger" message="Complete all fields and try again!!" />
      );
    }
  };

  return (
    <div
      className="modal fade add-banja"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="myLargeModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title h4" id="myLargeModalLabel">
              Add Customer Debt
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div className="modal-body">
            {loading ? <Loader /> : info}
            <div className="form-group">
              <label className="form-label">
                Customer <small style={{ color: "red" }}>* required</small>
              </label>
              <select
                type="text"
                value={customer_id}
                onChange={(e) => setCustomerId(e.target.value)}
                className="form-control"
              >
                <option value=""> --- select customer --- </option>
                {customerList &&
                  Array.isArray(customerList) &&
                  customerList.map((customer, key) => (
                    <option key={key} value={customer.id}>
                      {customer.name}
                    </option>
                  ))}
              </select>
            </div>
            <div className="form-group">
              <label className="form-label">
                Served By <small style={{ color: "red" }}>* required</small>
              </label>
              <select
                type="text"
                value={vendor_user_id}
                onChange={(e) => setVendorUserId(e.target.value)}
                className="form-control"
              >
                <option value=""> --- select a sales rep --- </option>
                {userList &&
                  Array.isArray(userList) &&
                  userList.map((user, key) => (
                    <option key={key} value={user.id}>
                      {user.first_name + " " + user.last_name}
                    </option>
                  ))}
              </select>
            </div>
            <div className="form-group">
              <label className="form-label">
                Amount <small style={{ color: "red" }}>* required</small>
              </label>
              <input
                type="text"
                value={credit}
                onChange={(e) => setCredit(e.target.value)}
                className="form-control"
                placeholder="Enter amount"
              />
            </div>
            <div className="form-group">
              <label className="form-label">
                Date <small style={{ color: "red" }}>* required</small>
              </label>
              <input
                type="date"
                value={date_added}
                onChange={(e) => setDateAdded(e.target.value)}
                className="form-control"
                placeholder="Enter date"
              />
            </div>
            <div className="form-group">
              <label className="form-label">Memo</label>
              <textarea
                onChange={(e) => setMemo(e.target.value)}
                className="form-control"
                placeholder="Enter credit notes"
                value={memo}
              ></textarea>
            </div>
            <div className="d-grid">
              <button
                onClick={registerBanja}
                className="btn btn-primary btn-block mt-2"
              >
                Add Customer Credit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddBanja;
