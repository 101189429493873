import React, { useEffect, useState } from "react";
import AppContainer from "../Components/Structure/AppContainer";
import Loader from "../Components/Common/Loader";
import { useParams } from "react-router-dom";
import ajaxVendor from "../util/remote/ajaxVendor";
import AddMenuItem from "../Components/Modal/AddMenuItem";

function CollectorProfile() {
  const { id } = useParams();
  const [vendor, setVendor] = useState("");
  const [loading, setLoading] = useState(true);

  const getVendor = async () => {
    setLoading(true);
    const server_response = await ajaxVendor.getVendor(id);
    setLoading(false);
    // console.log(server_response);

    if (server_response.status === "OK") {
      setVendor(server_response.details);
    } else {
      setVendor("404");
    }
  };

  useEffect(() => {
    getVendor();
  }, [id]);

  return (
    <AppContainer
      title={"Collectors Profile"}
      href={"collector"}
      parent={"Collectors"}
    >
      <div className="col-12">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <div className="float-end">
                  <button
                    data-bs-toggle="modal"
                    data-bs-target=".add-menu-item"
                    className="btn btn-sm btn-primary me-2"
                  >
                    <i className="ti ti-plus" />
                    Add Menu Item
                  </button>
                  <AddMenuItem getList={getVendor} vendor={id} />
                </div>
                <h4 className="mb-3 text-warning">Collectors Details</h4>
                <hr />
                {loading && <Loader />}
                {vendor && vendor != "404" && (
                  <div className="row">
                    <div className="col-lg-6">
                      <h2 className="mt-0">{vendor.business_name}</h2>
                      <div className="mt-2">
                        <small className="text-muted mb-0">
                          Vendor Number:
                        </small>
                        <h5>{vendor.vendor_no}</h5>
                      </div>
                      <div className="mt-2">
                        <small className="text-muted mb-0">Owner:</small>
                        <h5>{vendor.owners_name}</h5>
                      </div>
                      <div className="mt-1">
                        <small className="text-muted mb-0">Phone:</small>
                        <h5>{vendor.phone}</h5>
                      </div>
                      <div className="mt-1">
                        <small className="text-muted mb-0">Email:</small>
                        <h5>{vendor.email}</h5>
                      </div>
                      <div className="mt-1">
                        <small className="text-muted mb-0">Location:</small>
                        <h5>{vendor.location}</h5>
                      </div>
                      <div className="mt-1">
                        <small className="text-muted mb-0">Username:</small>
                        <h5>{vendor.user.username}</h5>
                      </div>
                      <div className="mt-1">
                        <small className="text-muted mb-0">Added By:</small>
                        <h5>
                          {vendor.created_by.first_name}{" "}
                          {vendor.created_by.last_name}
                        </h5>
                      </div>
                      <div className="mt-1">
                        <small className="text-muted mb-0">Date Added:</small>
                        <h5>{vendor.created_at.long_date}</h5>
                      </div>

                      <div className="row mt-4">
                        <div className="col-sm-12">
                          <div className="form-group">
                            <label className="form-label text-primary">
                              Bank Details
                            </label>
                            <div className="mt-1">
                              <small className="text-muted mb-0">Bank:</small>
                              <h5>{vendor.bank.bank_name}</h5>
                            </div>
                            <div className="mt-1">
                              <small className="text-muted mb-0">
                                Account Number:
                              </small>
                              <h5>{vendor.account_no}</h5>
                            </div>
                            <div className="mt-1">
                              <small className="text-muted mb-0">
                                Account Name:
                              </small>
                              <h5>{vendor.account_name}</h5>
                            </div>
                            <div className="mt-1">
                              <small className="text-muted mb-0">
                                Opening Branch:
                              </small>
                              <h5>{vendor.opening_branch}</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="table-responsive">
                        <h4 className="text-center text-warning">
                          Collectors Menu
                        </h4>
                        <table className="table table-hover table-bordered mb-0">
                          <thead>
                            <tr>
                              <th>#No</th>
                              <th>Collection</th>
                            </tr>
                          </thead>
                          <tbody>
                            {vendor.menu &&
                              Array.isArray(vendor.menu) &&
                              vendor.menu.map((item, key) => (
                                <tr key={key}>
                                  <td>{key + 1}</td>
                                  <td>{item.menu_item}</td>
                                </tr>
                              ))}
                            {!Array.isArray(vendor.menu) && (
                              <tr>
                                <td
                                  colSpan={2}
                                  className="text-center text-danger"
                                >
                                  No menu item found
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                      <div className="table-responsive mt-4">
                        <h4 className="text-center text-warning">
                          Users Registered
                        </h4>
                        <table className="table table-hover table-bordered mb-0">
                          <thead>
                            <tr>
                              <th>#No</th>
                              <th>Name</th>
                              <th>T.contributions</th>
                              <th>T.pledges</th>
                            </tr>
                          </thead>
                          <tbody>
                            {!Array.isArray(vendor.users) && (
                              <tr>
                                <td
                                  colSpan={4}
                                  className="text-center text-danger"
                                >
                                  No users registered yet
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                )}
                {vendor && vendor == "404" && (
                  <h5 className="text-center text-danger">No vendor found</h5>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </AppContainer>
  );
}

export default CollectorProfile;
