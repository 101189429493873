import React, { useState } from "react";
import ajaxUser from "../util/remote/ajaxUser";
import Loader from "../Components/Common/Loader";
import Alert from "../Components/Common/Alert";
import dictionary from "../util/dictionary";

function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [info, setInfo] = useState("");

  const [loading, setLoading] = useState(false);

  const loginUser = async (e) => {
    e.preventDefault();
    if (username.length > 0 && password.length > 0) {
      setLoading(true);
      const server_response = await ajaxUser.loginUser(username, password);
      setLoading(false);

      if (server_response.status === "OK") {
        localStorage.setItem("vendor_pay@user", server_response.details);
        window.location.reload();

        setInfo(<Alert type="success" message={server_response.message} />);
      } else {
        setInfo(<Alert type="danger" message={server_response.message} />);
      }
    } else {
      setInfo(
        <Alert type="danger" message="Complete all fields and try again!!" />
      );
    }
  };

  return (
    <div className="auth-wrapper authentication-signin bg-primary ">
      <div className="auth-content">
        <div className="row align-items-center justify-content-center text-start">
          <div className="col-xl-4 col-lg-8 col-md-10">
            <div className="card mb-0">
              <div className="card-body">
                <div className="border p-4 rounded">
                  <div className="text-center">
                    <img
                      src={dictionary.apiHost + "img/logo.jpg"}
                      alt="logo"
                      className="width-150 mx-auto d-block mb-3"
                    />

                    <h3>Sign in</h3>
                    {loading ? <Loader /> : info}
                  </div>
                  <div className="row mt-4 justify-content-center">
                    <div className="col-lg-10">
                      <div className="form-group mb-3">
                        <label className="form-label">
                          Enter Email address
                        </label>
                        <input
                          type="text"
                          value={username}
                          onChange={(e) => setUsername(e.target.value)}
                          className="form-control"
                          placeholder="Enter Username"
                        />
                      </div>
                      <div className="form-group mb-3">
                        <label className="form-label">Enter Password</label>
                        <input
                          type="password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          className="form-control"
                          placeholder="Enter Password"
                        />
                      </div>

                      <div className="d-grid">
                        <button
                          onClick={loginUser}
                          className="btn btn-primary btn-block mt-2"
                        >
                          Sign In
                        </button>
                      </div>
                      <div className="row mt-4 mb-4">
                        <div className="col-12">
                          <p className="text-center">
                            <a href="#">Password Reset</a>
                          </p>
                        </div>

                        <div className="col-4">
                          <div className="d-grid">
                            {/* <button className="btn btn-light">
                          <img src="../assets/images/auth/img-google.svg" alt className="img-fluid width-25" />
                        </button> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
