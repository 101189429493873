import React, { useEffect, useState } from "react";
import Loader from "../Common/Loader";
import { RenderSecure } from "../../util/script/RenderSecure";
import Alert from "../Common/Alert";
import functions from "../../util/functions";
import ajaxPayments from "../../util/remote/ajaxPayments";

function BankTransfers() {
  const [paymentsList, setPaymentsList] = useState([]);
  const [data, setData] = useState({ page: "1" });
  const [status, setStatus] = useState(null);
  const [loading, setLoading] = useState(false);
  const [info, setInfo] = useState("");
  const [vendor_id, setPaymentsVendor] = useState(functions.getVendor());
  const [limit, setLimit] = useState(100);

  useEffect(() => {
    listPayments(true);
  }, [data, status]);

  const listPayments = async (load) => {
    load && setLoading(true);
    const server_response = await ajaxPayments.listPayments(
      vendor_id,
      limit,
      status
    );
    setLoading(false);

    console.log(server_response.status);

    if (server_response.status === "OK") {
      setPaymentsList(server_response.details);
    } else {
      setPaymentsList("404");
      setInfo(<Alert type="danger" message={server_response.message} />);
    }
  };

  const setstatuss = (no) => {
    setStatus(no);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      listPayments(false);
    }, 2000);
    return () => clearInterval(intervalId);
  });

  return (
    <div className="col-12">
      <div className="card table-card">
        <div className="card-header">
          <h4 style={{ float: "left" }}>Bank Transfers</h4>
          <div style={{ float: "right" }}>
            <a href="payment" className="badge bg-primary p-2 px-3">
              View All
            </a>
            <span style={{ visibility: "hidden" }}>a</span>
            <badge
              onClick={() => setstatuss(3)}
              className="badge bg-success p-2 px-3"
            >
              Successfull
            </badge>
            <span style={{ visibility: "hidden" }}>a</span>
            <badge
              onClick={() => setstatuss(1)}
              className="badge bg-primary p-2 px-3"
            >
              Pending
            </badge>
            <span style={{ visibility: "hidden" }}>a</span>
            <badge
              onClick={() => setstatuss(2)}
              className="badge bg-danger p-2 px-3"
            >
              Failed
            </badge>
          </div>
        </div>
        <div className="card-body">
          <div className="table-responsive">
            {loading && <Loader />}
            {!loading && (
              <table className="table" id="pc-dt-simple">
                <thead>
                  <tr>
                    <th>#</th>
                    <RenderSecure code="ADD VENDOR">
                      <th>Vendor</th>
                    </RenderSecure>
                    <th>Customer</th>
                    <th>Amount</th>
                    <th>Bank</th>
                    <th>Description</th>
                    <th>Status</th>
                    <th>Date Time</th>
                  </tr>
                </thead>
                <tbody>
                  <Alert type="danger" message="No bank transfers found" />
                  {/* {paymentsList &&
                    Array.isArray(paymentsList) &&
                    paymentsList.map((payment, key) => (
                      <tr key={key}>
                        <td>{key + 1}</td>
                        <RenderSecure code="ADD VENDOR">
                          <td>{payment.vendor.business_name}</td>
                        </RenderSecure>
                        <td>
                          {payment.customer
                            ? payment.customer.name
                            : "Unregistered"}
                          <br />
                          {payment.phone}
                        </td>

                        <td>{payment.cash_in}</td>
                        <td>
                          {payment.sales_point.sales_point}
                          <br />
                          {payment.vendor_user.first_name +
                            " " +
                            payment.vendor_user.last_name}
                        </td>
                        <td>
                          {payment.status == 1 ? (
                            <span className="badge bg-primary">Pending</span>
                          ) : payment.status == 3 ? (
                            <span className="badge bg-success">
                              Successfull
                            </span>
                          ) : (
                            <span className="badge bg-danger">Failed</span>
                          )}
                        </td>
                        <td>{payment.created_at.long_date}</td>
                      </tr>
                    ))} */}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default BankTransfers;
