import React, { useState } from "react";
import Loader from "../Common/Loader";
import Alert from "../Common/Alert";
import ajaxVendor from "../../util/remote/ajaxVendor";
import functions from "../../util/functions";

function AddMenuItem(props) {
  const [menu, setMenu] = useState("");

  const [loading, setLoading] = useState("");
  const [info, setInfo] = useState("");

  const registerItem = async (e) => {
    if (menu.length > 0) {
      setLoading(true);
      const server_response = await ajaxVendor.addVendorItem(
        props.vendor,
        menu
      );
      setLoading(false);

      if (server_response.status === "OK") {
        props.getList();
        setMenu("");
        setInfo(<Alert type="success" message={server_response.message} />);
      } else {
        setInfo(<Alert type="danger" message={server_response.message} />);
      }
    } else {
      setInfo(
        <Alert type="danger" message="Complete all fields and try again!!" />
      );
    }
  };

  return (
    <div
      className="modal fade add-menu-item"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="myLargeModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title h4" id="myLargeModalLabel">
              Add Menu Item
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div className="modal-body">
            {loading ? <Loader /> : info}
            <div className="form-group">
              <label className="form-label">
                Menu Item <small style={{ color: "red" }}>* required</small>
              </label>
              <input
                type="text"
                value={menu}
                onChange={(e) => setMenu(e.target.value)}
                className="form-control"
                placeholder="Enter menu item"
              />
            </div>
            <div className="d-grid">
              <button
                onClick={registerItem}
                className="btn btn-primary btn-block mt-2"
              >
                Register Item
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddMenuItem;
