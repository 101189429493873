import React from "react";
import { RenderSecure } from "../../util/script/RenderSecure";
import dictionary from "../../util/dictionary";

function SideBar() {
  return (
    <aside id="navvvv" className="app-sidebar app-light-sidebar">
      <div className="app-navbar-wrapper">
        <div className="brand-link brand-logo">
          <a href="#" className="b-brand">
            {/* ========   change your logo hear   ============ */}
            <img
              src={dictionary.apiHost + "img/logo.jpg"}
              alt
              className="logo logo-lg"
            />
          </a>
        </div>
        <div className="navbar-content">
          <ul className="app-navbar">
            <li className="nav-item nav-hasmenu">
              <a href="/home" className="nav-link">
                <span className="nav-icon">
                  <i className="ti ti-layout-2" />
                </span>
                <span className="nav-text">Dashboard</span>
              </a>
            </li>
            <RenderSecure code="ADD VENDOR">
              <li className="nav-item nav-hasmenu">
                <a href="/vendor" className="nav-link">
                  <span className="nav-icon">
                    <i className="ti ti-users" />
                  </span>
                  <span className="nav-text">Vendors</span>
                </a>
              </li>
            </RenderSecure>
            <RenderSecure code="ADD VENDOR">
              <li className="nav-item nav-hasmenu">
                <a href="/collector" className="nav-link">
                  <span className="nav-icon">
                    <i className="ti ti-users" />
                  </span>
                  <span className="nav-text">Collectors</span>
                </a>
              </li>
            </RenderSecure>
            <RenderSecure code="VIEW CREDITS">
              <li className="nav-item nav-hasmenu">
                <a href="/banja" className="nav-link">
                  <span className="nav-icon">
                    <i className="ti ti-users" />
                  </span>
                  <span className="nav-text">Customer Debts</span>
                </a>
              </li>
            </RenderSecure>
            <RenderSecure code="ADD SALESPOINT">
              <li className="nav-item nav-hasmenu">
                <a href="/salespoint" className="nav-link">
                  <span className="nav-icon">
                    <i className="ti ti-users" />
                  </span>
                  <span className="nav-text">Sales Points</span>
                </a>
              </li>
            </RenderSecure>
            <RenderSecure code="ADD CUSTOMER">
              <li className="nav-item nav-hasmenu">
                <a href="/customer" className="nav-link">
                  <span className="nav-icon">
                    <i className="ti ti-users" />
                  </span>
                  <span className="nav-text">Customers</span>
                </a>
              </li>
            </RenderSecure>
            <RenderSecure code="VIEW PAYMENTS">
              <li className="nav-item nav-hasmenu">
                <a href="/payment" className="nav-link">
                  <span className="nav-icon">
                    <i className="ti ti-shopping-cart" />
                  </span>
                  <span className="nav-text">Transactions Log</span>
                </a>
              </li>
            </RenderSecure>

            <RenderSecure code="ADD USER">
              <li className="nav-item nav-hasmenu">
                <a href="/pendings" className="nav-link">
                  <span className="nav-icon">
                    <i className="ti ti-notebook" />
                  </span>
                  <span className="nav-text">Pending Transfers</span>
                </a>
              </li>
              <li className="nav-item nav-hasmenu">
                <a href="/faileds" className="nav-link">
                  <span className="nav-icon">
                    <i className="ti ti-notebook" />
                  </span>
                  <span className="nav-text">Failed Transfers</span>
                </a>
              </li>
              <li className="nav-item nav-caption">
                <label>General</label>
              </li>
              {/* ///////////////////statements */}
              <li className="nav-item nav-hasmenu nav-provoke">
                <a href="#!" className="nav-link">
                  <span className="nav-icon">
                    <i className="ti ti-clipboard-check" />
                  </span>
                  <span className="nav-text">Statements</span>
                  <span className="nav-arrow">
                    <i data-feather="chevron-right" />
                  </span>
                </a>
                <ul className="nav-submenu">
                  <li className="nav-item">
                    <a className="nav-link" href="deposits">
                      Deposit Statement
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="banktransfers">
                      Bank Transfer Statement
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="batchtransfers">
                      Batch Transfer Statement
                    </a>
                  </li>
                </ul>
              </li>
            </RenderSecure>
            <RenderSecure code="ADD USER">
              <li className="nav-item nav-hasmenu">
                <a href="/sysuser" className="nav-link">
                  <span className="nav-icon">
                    <i className="ti ti-users" />
                  </span>
                  <span className="nav-text">System Users</span>
                </a>
              </li>
            </RenderSecure>
          </ul>
        </div>
      </div>
    </aside>
  );
}

export default SideBar;
