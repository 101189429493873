import React, { useEffect, useState } from "react";
import ajaxSalesPoints from "../util/remote/ajaxSalesPoints";
import functions from "../util/functions";

const SalesPointContext = React.createContext();

export const SalesPointConsumer = SalesPointContext.Consumer;

export const SalesPointProvider = (props) => {
  const [salesPointsList, setSalesPointList] = useState([]);
  const [vendor_id, setVendorID] = useState(functions.getVendor());

  useEffect(() => {
    listSalesPoint();
  }, [vendor_id]);

  const listSalesPoint = async () => {
    const server_response = await ajaxSalesPoints.listSalesPoints(vendor_id);

    if (server_response.status === "OK") {
      setSalesPointList(server_response.details);
    } else {
      setSalesPointList("404");
    }
  };

  return (
    <SalesPointContext.Provider
      value={{
        setVendorID,
        listSalesPoint,
        salesPointsList,
      }}
    >
      {props.children}
    </SalesPointContext.Provider>
  );
};

export default SalesPointContext;
