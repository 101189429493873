import React from "react";
import AppContainer from "../Components/Structure/AppContainer";
import UserTile from "../Components/Dashboard/UserTile";
import PaymentsList from "../Components/Dashboard/PaymentsList";
import { RenderSecure } from "../util/script/RenderSecure";
import CustomerCount from "../Components/Tiles/CustomerCount";
import SalesPointCount from "../Components/Tiles/SalesPointCount";
import VendorsCount from "../Components/Tiles/VendorsCount";
import UsersCount from "../Components/Tiles/UsersCount";
import TotalBanja from "../Components/Tiles/TotalBanja";
import TotalPayments from "../Components/Tiles/TotalPaymets";
import BankTransfers from "../Components/Dashboard/BankTransfers";

function Dashboard() {
  return (
    <AppContainer title={"Dashboard"} href={"home"} parent={"Home"}>
      <div className="row">
        {/* ////////////user tile */}
        <UserTile />

        <RenderSecure code="ADD CUSTOMER">
          <CustomerCount />
        </RenderSecure>

        <RenderSecure code="ADD SALESPOINT">
          <SalesPointCount />
        </RenderSecure>

        <RenderSecure code="ADD VENDOR">
          <VendorsCount />
        </RenderSecure>

        <RenderSecure code="ADD USER">
          <UsersCount />
        </RenderSecure>

        <RenderSecure code="VIEW CREDITS">
          <TotalBanja />
        </RenderSecure>

        <RenderSecure code="VIEW PAYMENTS">
          <TotalPayments />
        </RenderSecure>
      </div>
      <div className="row">
        <RenderSecure code="ADD SALESPOINT">
          <div className="col-xxl-6 col-lg-6 col-12">
            <div className="card">
              <div className="card-header">
                <h4>Sales Per Sales Point</h4>
              </div>
              <div className="card-body">
                <div id="Sales-chart" />
              </div>
            </div>
          </div>
          <div className="col-xxl-6 col-lg-6 col-12">
            <div className="card">
              <div className="card-header">
                <h4>Sales Per Sales Representative</h4>
              </div>
              <div className="card-body">
                <div id="Sales-chart1" />
              </div>
            </div>
          </div>
        </RenderSecure>
        <RenderSecure code="VIEW PAYMENTS">
          <PaymentsList />
        </RenderSecure>
        <RenderSecure code="ADD USER">
          <BankTransfers />
        </RenderSecure>
      </div>
    </AppContainer>
  );
}

export default Dashboard;
